@import 'config/sass/vars';

.datepickerContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    label {
        @include h3;

        color: $primary-dark-green;
        margin-bottom: 1rem;
    }

    [type='date'] {
        display: block;
        appearance: none;
        background-color: #fff;
        border-radius: 3rem;
        border: 1px solid $secondary-green-900;
        font-size: 1rem;
        padding: 1rem;
        color: $primary-dark-green;

        &:disabled {
            cursor: not-allowed;
            background-color: $secondary-grey-100;
        }
    }

    &.error {
        label {
            color: $error;
        }

        [type='date'] {
            border-color: $error;
            color: $error;
        }
    }

    .errorMessage {
        color: $error;
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-weight: 600;
    }
}

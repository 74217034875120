@import 'config/sass/vars';

.card {
    background: #fff;
    border-radius: 1.25rem;
    border: 1px solid rgba($primary-dark-green, 0.3);
    margin: 1rem auto;
    box-sizing: border-box;
    width: 95%;
    max-width: 400px;

    .cardHeader {
        background-color: $secondary-green-500;
        color: #fff;
        text-align: center;
        padding: 1rem 0;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba($primary-dark-green, 0.3);
        padding-bottom: 1.5rem;
        position: relative;
        border-radius: 1.25rem 1.25rem 0 0;

        .price {
            h2 {
                margin-top: 1rem;
            }
        }

        &.disabled {
            background-color: #eaeaea;
            color: #808080;
        }

        h3 {
            color: inherit;
            margin: 0;
            font-size: 1.5rem;
        }

        h2 {
            color: inherit;
            margin: 0.5rem 0 0;
            font-size: 2.5rem;
        }

        h2 + span {
            font-size: 0.75rem;
        }

        h4 {
            > svg {
                position: absolute;
                transform: scale(1.125);

                path {
                    stroke: $primary-off-white;
                }
            }

            color: inherit;
            margin: 1rem 0 -0.5rem 0;
            font-size: 1.5rem;
        }

        .priceYourHome h4 > svg path {
            stroke: $primary-dark-green;
        }

        .subheading {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(50%);
            bottom: 0;
            padding: 10px 25px 10px 25px;
            border-radius: 6px;
            background-color: $primary-highlight-green;
            max-width: 600px;

            h4 {
                @include h4;

                font-family: $font-body;
                line-height: 96%;
                font-weight: 500;
                font-size: 0.75rem !important;
                color: $primary-dark-green;
                margin: 0px;
            }
        }

        .discount {
            width: 90px;
            height: 90px;

            &::before {
                content: '';
                border-radius: 100%;
                background-color: $secondary-purple-100;
                position: absolute;
                width: 90px;
                height: 90px;
                top: 0;
                left: 0;
                z-index: -1;
            }

            height: 0;
            top: -45px;
            right: -15px;
            position: absolute;
            color: $secondary-purple-900;
            font-size: 1.75rem;
            z-index: 10;
            font-weight: 700;
            font-family: $font-heading;
            padding-top: 1.5rem;
            line-height: 85%;
        }
    }

    .cardItems {
        display: grid;
        grid-template-columns: 2fr 1fr;
        row-gap: 1rem;
        padding: 0.5rem 2rem 0.75rem 2rem;

        .cardLabel {
            font-size: 0.875rem;
        }

        .cardValue {
            text-align: right;
            font-size: 0.75rem;
            height: 24px;

            &.disabled {
                color: #808080;

                svg path {
                    fill: #808080;
                }
            }
        }
    }

    .cardCta {
        padding: 0 0 1rem 0;
        text-align: center;

        button {
            font-size: 0.8rem;
            font-weight: 500;
        }
    }

    .inclusions {
        padding: 0rem 3rem 1rem 3rem;

        h4 {
            font-size: 1.5rem;
        }

        .bullets {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .inclusionsPoint {
                display: flex;
                align-items: center;
                text-align: start;
                gap: 1rem;

                svg {
                    height: 1rem;
                }

                p {
                    width: 80%;
                    margin: 0;
                }
            }
        }
    }
}

.popover {
    position: relative;
    font-size: 1rem;
    display: block;

    .popoverTitle {
        text-decoration: underline;
    }
}

.popoverContent {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: $primary-highlight-green;
    border-radius: 1.25rem;
    padding: 1rem;
    width: 100%;
    text-align: left;
}

.popover:hover .popoverContent,
.popover:active .popoverContent {
    z-index: 10;
    opacity: 1;
    visibility: visible;
}

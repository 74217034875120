@import 'config/sass/vars';

@media (max-width: $breakpoint-mobile) {
    .desktopHeading {
        display: none;
    }
}

.mobileHeading {
    display: none;

    @media (max-width: $breakpoint-mobile) {
        display: initial;
        background-color: $primary-mid-green-2;
        text-align: center;
        h1 {
            color: $primary-off-white;
            margin-top: 40px;
        }
    }
}

.form {
    background-color: white;
    padding: 2rem 4rem;
    margin: 2rem auto;
    width: 75%;
    max-width: $breakpoint-desktop;
    border-radius: 40px;
    border: 1px solid rgba($primary-mid-green-2, 0.1);

    select {
        width: 100%;
    }

    button {
        min-width: 33%;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }

    a {
        color: $secondary-green-500;
    }

    a:visited {
        color: $secondary-purple-500;
    }

    h2 {
        margin-bottom: 1rem;
    }

    h3 {
        margin-bottom: 0px;
    }

    .whatsNextText {
        margin-bottom: 3.1rem;
    }

    .linkSection {
        display: flex;
        flex-direction: row;
        align-self: center;

        .refLink {
            margin-top: 10px;
        }

        .copyBtn {
            min-width: 117px;
            height: 38px;
            background-color: $primary-highlight-green;
            border: none;
            border-radius: 11px;
            margin-left: 20px;
            margin-top: 0px;
        }

        .shareBtn {
            display: none;
        }
    }

    .reviewSection {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 0;
        border-radius: 0;
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem 1.5rem 2rem;

        .title {
            margin-top: 13px;
        }

        .navbar_content {
            top: 0px;
        }

        .whatsNextText {
            margin-bottom: 0px;
        }

        h3 {
            margin-top: 12px;
        }

        .linkSection {
            flex-direction: column;

            .copyBtn {
                display: none;
            }

            .shareBtn {
                color: $secondary-black;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 117px;
                height: 38px;
                background-color: $primary-highlight-green;
                border: none;
                border-radius: 11px;
                margin-bottom: 0px;

                svg {
                    margin-left: 5px;
                }
            }
        }
    }
}

.multipleOptionSelectContainer > div > div {
    max-width: 650px;
}

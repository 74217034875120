@import 'config/sass/vars';

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    background-color: rgba($primary-dark-green, 0.6);
    z-index: 1000;

    .modal {
        background-color: $primary-off-white;
        border-radius: 1.5rem;
        padding: 45px 40px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        position: relative;
        max-width: 80%;
        width: 550px;
        margin: 40px auto;

        &.hidden {
            display: none;
        }

        h2 {
            margin-top: 0;
        }
    }

    .closeButton {
        position: absolute;
        top: 2.8rem;
        right: 2.5rem;
        border: none;
        background: transparent;
        font-size: 24px;
        cursor: pointer;
        color: $primary-dark-green;
        padding: 0;
        width: 24px;
        height: 24px;

        > svg {
            height: 100%;
            width: 100%;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
    }

    .loadingContainer {
        > div {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            > svg {
                animation: rotation 1.5s infinite linear;
            }
        }
    }

    @media (max-width: $breakpoint-mobile) {
        .modal {
            max-width: 500px !important;
            width: calc(100% - 5rem) !important;
            padding: 25px 20px;

            hr {
                margin: 1rem 0 2.75rem;
            }
        }

        .closeButton {
            top: 1.5rem;
            right: 1.2rem;
            width: 24px;
            height: 24px;
        }
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@import 'config/sass/vars';

.errorScreenContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
        height: 1px;
        margin: 1.8rem 0 2.8rem;
        background-color: rgba($primary-dark-green, 0.2);
        border: none;
        width: 100%;
    }

    h2 {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 2rem;
    }

    button {
        margin-bottom: 1.5rem;
    }
}

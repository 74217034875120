@import 'config/sass/vars';

.banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $secondary-purple-100;
    position: sticky;
    z-index: 30;
    top: 0;

    .desktop {
        @media (max-width: calc($breakpoint-mobile)) {
            display: none;
        }

        flex: 1;

        .content {
            color: $secondary-purple-900;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.75rem 0;
            justify-content: center;

            h3 {
                color: inherit;
                margin: 0;
                padding: 0 1rem;
            }

            .tc {
                padding: 0 1rem;
            }
        }
    }

    .mobile {
        @media (min-width: calc($breakpoint-mobile)) {
            display: none;
        }

        transition: all 1s ease-in-out;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        flex: 1;
        justify-content: center;
        text-align: center;

        .content {
            color: $secondary-purple-900;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            text-align: center;
            justify-content: space-between;
            z-index: 29;

            @media (max-width: 440px) {
                .tc > h3 {
                    font-size: 1rem;
                }
            }
            h3 {
                color: inherit;
                margin: 0.125rem;
                font-size: 1.25rem;
            }

            span {
                font-size: 0.9rem;
            }
        }
    }

    .closeButton {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 2%;
    }
}

@import 'config/sass/vars';

.footer {
    background-color: $primary-yellow;
    padding: 5em 1em;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-mobile) {
        padding: 2.5em 1em;
    }

    > div {
        max-width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (max-width: $breakpoint-mobile) {
            align-items: flex-start;

            .footerLogo {
                width: 140px;
            }
        }
    }

    .linksContainer {
        display: flex;
        justify-content: space-between;
        padding: 4em 0 0;
        margin: 0 auto;
        width: 100%;
        gap: 0.5rem;

        > div {
            flex-grow: 1;
        }

        @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            gap: 0;
            padding: 1.5em 0 0;

            br {
                display: none;
            }
        }

        .linkGroup {
            button {
                border: none;
                background: none;
                padding: 0;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                margin-bottom: 1rem;

                h3 {
                    margin: 0;
                }

                svg {
                    display: none;
                }
            }

            a {
                color: $primary-dark-green;
                text-decoration: none;
                padding: 0 0 0.375rem;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 1rem;
                }
            }

            @media (max-width: $breakpoint-mobile) {
                margin-top: 1rem;

                button {
                    margin: 0;
                    cursor: pointer;

                    svg {
                        display: block;
                        transition: transform 0.2s ease-in-out;
                    }
                }

                ul {
                    margin-top: 1rem;
                    display: none;
                }

                &.expanded {
                    ul {
                        display: block;
                    }

                    button > svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .socialMediaLinks {
        display: flex;
        margin-top: 2.25rem;
        gap: 0.875rem;

        &.hideDesktop {
            display: none;
        }

        a {
            width: 25px;

            svg {
                fill: $primary-dark-green;
            }
        }

        @media (max-width: $breakpoint-mobile) {
            gap: 1rem;

            &.hideDesktop {
                display: flex;
                margin: 2.5rem 0;
            }

            &.hideMobile {
                display: none;
            }

            a {
                width: 30px;
            }
        }
    }

    .hr {
        width: 100%;
        border-bottom: 1px solid $primary-dark-green;
        margin-top: 3.5rem;
        margin-bottom: 4.5rem;
        position: relative;

        svg {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        @media (max-width: $breakpoint-mobile) {
            display: none;
        }
    }

    p.legalText {
        font-size: 0.875rem;
        line-height: 1.4em;
    }
}

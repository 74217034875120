@import 'config/sass/vars';

.billingAddressForm {
    .inputContainer {
        margin-bottom: 1rem;

        label {
            font-size: $base-font-size;
            letter-spacing: -0.04rem;
            margin-bottom: 0.5rem;
            font-weight: 400;
            font-family: $font-body;

            @media (max-width: $breakpoint-mobile) {
                font-size: 14px;
            }
        }

        input,
        select {
            padding: 0.7rem;
        }
    }

    .rowInputs {
        width: 100%;
        justify-content: space-between;
        display: flex;
        gap: 0.6rem;

        > div {
            flex-grow: 1;

            input {
                box-sizing: border-box;
            }
        }

        @media (max-width: 480px) {
            flex-direction: column;
        }
    }

    h3 {
        margin-top: 1.5rem;
    }
}

.usePropertyAsBillingCheckbox {
    margin-top: 1.5rem;
    background-color: $secondary-green-100;
    border-radius: 1.25rem;
    padding: 0.75rem 1.35rem;

    p {
        margin-top: 0.5rem;
        padding-left: 35px;
    }

    @media (max-width: $breakpoint-mobile) {
        border-radius: 0;
        margin: 1.5rem -20px 0;
    }
}

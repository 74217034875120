@import 'config/sass/vars';

.checkoutContainer {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    margin: 0 auto;
    width: $breakpoint-desktop;
    max-width: 100%;
    padding: 1.6rem 0;

    > div {
        background: #fff;
        border-radius: 1.25rem;
        padding: 1.5rem 1.6rem;

        h1 {
            margin-top: 0;
            margin-bottom: 1.25rem;
        }

        &.orderForm {
            height: fit-content;
            order: 1;
            flex-grow: 1;
            flex-basis: 35%;
            border: 3px solid rgba($primary-dark-green, 0.1);
        }

        &.checkoutForm {
            order: 0;
            flex-basis: 65%;
            flex-grow: 1;
            border: 3px solid rgba($primary-dark-green, 0.1);
        }
    }

    @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        padding: 0;
        gap: 0;

        > div {
            border-radius: 0;
            border-bottom: 1px solid rgba($primary-dark-green, 0.3);

            &.orderForm {
                order: 0;
            }

            &.checkoutForm {
                order: 1;
            }
        }
    }
}

@import 'config/sass/vars';

.container {
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
    padding: 3rem 0rem 5rem 0rem;
    width: 100%;
    background-color: $primary-yellow;

    h4 {
        font-size: 1.25rem;
        text-align: left;
        font-family: $font-body;
    }

    h1 {
        padding: 0 1rem;
        text-align: center;
    }
    @media (max-width: calc($breakpoint-mobile + 200px)) {
        margin-bottom: 0rem;
        padding: 2rem 0rem 2rem 0rem;
    }
}

@import 'config/sass/vars';

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    background-color: $primary-yellow;
    border-radius: 2.5rem;
    width: 90%;
    max-width: $breakpoint-desktop;
    padding: 2rem 2.5rem 2rem;
    margin: 0 2rem 1rem;
    box-sizing: border-box;
    position: relative;

    h2 {
        margin-bottom: 1rem;

        &.hideMobile {
            display: block;
        }

        &.hideDesktop {
            display: none;
        }

        @media (max-width: $breakpoint-mobile) {
            &.hideMobile {
                display: none;
            }

            &.hideDesktop {
                display: block;
            }
        }
    }

    > div {
        margin: 0 auto;
        text-align: center;
        width: 800px;
        max-width: 100%;
    }

    .subheading {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(50%);
        bottom: 0;
        padding: 10px 5px 10px 5px;
        border-radius: 6px;
        border: 1px solid #172b024d;
        background-color: white;
        width: 230px;

        h4 {
            @include h4;

            line-height: 96%;
            color: $primary-dark-green;
            margin: 0px;
        }

        .tp {
            transform: scale(0.85);
        }
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 0 2rem 2rem 2rem;
        max-width: 95%;
        padding: 2rem 1.5rem;
        border-radius: 1rem;

        h2 {
            margin: 0;
            line-height: 130%;
        }

        p {
            display: none;
        }

        .subheading {
            display: flex;
        }

        &.hideMobile {
            display: none;
        }

        &.hideDesktop {
            display: block;
        }
        .tp {
            transform: scale(0.25);
        }
    }
}

.section {
    width: 100%;
    margin-bottom: 2rem;
}

.ctmPartnershipLogo {
    margin: 1.5rem 0 4rem;
    display: none;
    margin: 1.5rem 0 1rem;
    align-items: center;
    gap: 0.25rem;
    color: $secondary-blue;

    @media (max-width: $breakpoint-mobile) {
        display: flex;
        justify-content: space-between;
        width: 320px;
        max-width: 100%;

        svg {
            width: 175px;
            height: 40px;
        }
    }
}

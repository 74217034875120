@import 'config/sass/vars';

.registerCustomer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary-mid-green-2;
    padding: 7em 4em;
    margin-top: -1.5rem;

    .highlight {
        color: $primary-highlight-green;
        margin: 0px;
    }

    h3,
    h4,
    p {
        color: $primary-off-white;
        text-align: center;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 20px;
    }

    a {
        color: $secondary-green-100;
    }

    @media (max-width: $breakpoint-mobile) {
        margin-top: 0;
        padding: 4em 4em;
    }
}

.showMobile {
    display: none;

    @media (max-width: $breakpoint-mobile) {
        display: block;
    }
}

@import 'config/sass/vars';

.dropdownContainer {
    margin-bottom: 1.5rem;

    select {
        display: block;
        appearance: none;
        background-color: #fff;
        background-image: url('/assets/caret-down.svg');
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 21px;
        border-radius: 3rem;
        border: 1px solid $secondary-green-900;
        font-size: 1rem;
        padding: 1rem 2.5rem 1rem 1rem;
        color: $primary-dark-green;
        width: 100%;

        &:disabled {
            cursor: not-allowed;
            background-color: $secondary-grey-100;
        }
    }

    label {
        @include h3;

        display: block;
        color: $primary-dark-green;
        margin-bottom: 1rem;
    }

    &.error {
        select {
            border-color: $error;
            color: $error;
        }

        label {
            color: $error;
        }
    }

    .errorMessage {
        color: $error;
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-weight: 600;
    }
}

@import 'config/sass/vars';

.desktopOnly {
    @media (max-width: calc($breakpoint-mobile + 200px)) {
        display: none !important;
    }
}

.container {
    background-color: $primary-dark-green;
    background-image: url('../../../../assets/logo-background.svg');
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        width: 70%;
        display: flex;
        gap: 3rem;
        border-radius: 1rem;
        padding: 4rem 9rem;
        margin: 0 4rem;
        background-color: $primary-off-white;
        h1 {
            font-size: 5rem;
            width: 288px;
        }
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 152px;
            margin: auto;

            p {
                margin-bottom: 0.5rem;
            }

            .ctas {
                display: flex;
                margin-top: 1rem;
                gap: 1rem;

                button {
                    padding: 0.75rem 1.5rem;
                }
            }
        }
    }

    @media (max-width: calc($breakpoint-mobile + 200px)) {
        height: 550px;

        .content {
            flex-direction: column;
            gap: 0rem;
            padding: 2rem;
            margin: 0 1rem;

            h1 {
                font-size: 3rem;
                width: 70%;
            }
            > div {
                height: 100%;
                margin: 0;

                p {
                    font-size: 1.25rem;
                }

                .ctas {
                    flex-direction: column;
                }
            }
        }
    }
}

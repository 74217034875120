// Colours
$primary-yellow: #ffe53e;
$primary-highlight-green: #abde19;
$primary-mid-green-1: #83ac0c;
$primary-mid-green-2: #023e09;
$primary-dark-green: #172b02;
$primary-off-white: #fafbef;

$secondary-purple-100: #e7dcfd;
$secondary-purple-300: #a37fed;
$secondary-purple-500: #4f1fb0;
$secondary-purple-900: #160043;

$secondary-orange-100: #ffd6b0;
$secondary-orange-300: #ffa63d;
$secondary-orange-500: #ff8a00;
$secondary-orange-900: #5e340c;

$secondary-turquoise-100: #c4f8ff;
$secondary-turquoise-300: #3cd4e9;
$secondary-turquoise-500: #00b1d8;
$secondary-turquoise-900: #003137;

$secondary-green-100: #d4ffba;
$secondary-green-300: #44c991;
$secondary-green-500: #008e75;
$secondary-green-900: #024048;

$secondary-grey-100: #f5f3f3;
$secondary-grey-200: #d9d9d9;
$secondary-grey-300: #a5a3a3;
$secondary-grey-500: #807e7e;
$secondary-grey-900: #3e3d3d;
$secondary-black: #020202;

$secondary-blue: #0028d7;

$error: #dc1515;

$input-active-outline: #f8d162;

// Fonts
$font-heading: 'elza-condensed', sans-serif;
$font-body: 'Instrument Sans', sans-serif;

// Base
$base-font-size: 16px;

// Breakpoints
$breakpoint-mobile: 768px;
$breakpoint-desktop: 1200px;

@mixin h1 {
    font-size: 4rem;
    line-height: 95%;
    letter-spacing: -0.08rem;
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-family: $font-heading;

    @media (max-width: $breakpoint-mobile) {
        font-size: 2.5rem;
        letter-spacing: -0.05rem;
    }
}

@mixin h2 {
    font-size: 2.5rem;
    line-height: 90%;
    letter-spacing: -0.05rem;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: $font-heading;

    @media (max-width: $breakpoint-mobile) {
        font-size: 1.5rem;
        letter-spacing: -0.03rem;
    }
}

@mixin h3 {
    font-size: 1.5rem;
    line-height: 110%;
    letter-spacing: -0.03rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-family: $font-heading;

    @media (max-width: $breakpoint-mobile) {
        font-size: 1.125rem;
        letter-spacing: -0.045rem;
    }
}

@mixin h4 {
    font-size: 1.25rem;
    line-height: 110%;
    letter-spacing: 0.005rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-family: $font-heading;

    @media (max-width: $breakpoint-mobile) {
        font-size: 1rem;
        letter-spacing: 0.005rem;
    }
}

@import 'config/sass/vars';

.loadingBar {
    border-radius: 10px;
    border: solid $primary-dark-green 1px;
    background-color: $primary-off-white;
    height: 15px;
    position: relative;
    margin: 0 auto;
    width: 150px;

    > div {
        background-color: $primary-highlight-green;
        border-radius: 10px;
        bottom: 0;
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
    }
}

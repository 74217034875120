@import 'config/sass/vars';

.desktopOnly {
    @media (max-width: calc($breakpoint-mobile + 200px)) {
        display: none !important;
    }
}

.container {
    margin: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .image {
            width: auto;
            resize: both;
            svg {
                height: 95%;
                width: 95%;
            }
        }
        .text {
            padding: 0 5%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 30%;

            h1 {
                margin: 0 0 1rem 0;
            }

            p {
                margin-bottom: 2rem;
            }

            button {
                padding: 0.75rem;
                width: 40%;
            }
        }
    }

    @media (max-width: calc($breakpoint-mobile + 200px)) {
        margin: 0;
        gap: 4rem;

        .row {
            flex-direction: column;
            margin-top: 1rem;

            &.secondRow {
                flex-direction: column-reverse;
                margin-bottom: 2rem;
            }

            .image {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;
            }

            .text {
                max-width: 100%;
                line-height: 130%;
            }
        }
    }
}

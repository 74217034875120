@import 'config/sass/vars';

.plans-table-slick-slider {
    .slick-slider .slick-list {
        padding-top: 45px !important;
        margin-top: -45px;

        @media (max-width: calc($breakpoint-mobile + 300px)) {
            margin-top: -10px;
        }
    }
    .slick-slider {
        .slick-arrow {
            position: absolute;
            top: calc(50% - 25px);
            z-index: 20;
            cursor: pointer;
            height: 50px;
            width: 50px;
            border-radius: 25px;
            border: 1px solid $primary-dark-green;
            background-color: $primary-off-white;
            padding: 0;
            transition: all 200ms ease-in;

            &:hover:not(.slick-disabled) {
                opacity: 0.9;
                background-color: $primary-highlight-green;
            }

            &.slick-prev {
                left: 5px;
            }

            &.slick-next {
                right: 5px;
                transform: rotate(180deg);
            }

            &.slick-disabled {
                cursor: not-allowed;
                background-color: #fff;
                border-color: #b6bda8;

                svg path {
                    fill: #b6bda8;
                }
            }

            svg {
                vertical-align: middle;

                path {
                    transition: all 200ms ease-in;
                }
            }
        }

        .slick-dots {
            display: flex !important;
            width: 100%;
            flex-direction: row;
            justify-content: center;
            list-style: none;
            padding: 2rem;
            gap: 0.5rem;
            box-sizing: border-box;

            li {
                display: block;

                &.slick-active {
                    button {
                        background-color: $primary-dark-green;
                    }
                }

                button {
                    display: block;
                    color: transparent;
                    width: 4.5rem;
                    border: none;
                    height: 4px;
                    border-radius: 1rem;
                    cursor: pointer;
                    background-color: rgba($primary-dark-green, 0.3);
                }
            }
        }
    }
}
.header-essentials {
    &.table-header,
    div.plan-card-header {
        background-color: $secondary-grey-200;
        color: $primary-dark-green;
    }

    &.table-header span {
        font-size: 0.75rem;
    }
}

.header-boiler {
    &.table-header,
    div.plan-card-header {
        background-color: $secondary-green-500;
        color: #fff;
    }
}

.header-heating {
    &.table-header,
    div.plan-card-header {
        background-color: $secondary-purple-500;
        color: #fff;
    }
}

.header-home {
    &.table-header,
    div.plan-card-header {
        background-color: $primary-yellow;
        color: $primary-dark-green;
    }
}

h4 > svg {
    path {
        stroke: $primary-dark-green;
    }
}

@import 'config/sass/vars';

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
    overflow: hidden;
    width: 100%;

    h2 {
        font-size: 3rem;
        margin-bottom: 1rem;
        text-align: center;
    }
    p {
        margin-bottom: 2rem;
        padding: 0 1rem;
        text-align: center;
    }

    @media (max-width: calc($breakpoint-mobile + 200px)) {
        margin-bottom: 2rem;
    }
}

$animationSpeed: 40s;

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-160px * 6));
    }
}

.slider {
    background: $primary-off-white;
    height: 135px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;

    .slideTrack {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        gap: 2rem;
        width: calc(160px * 36);
    }

    .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;
        width: 160px;
        border-radius: 1rem;
        background-color: white;
        border: 1px solid rgba($primary-dark-green, 0.3);
    }
}

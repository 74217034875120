@import "config/sass/vars";

.marketingOptIn {
    margin: 0 -1.6rem 1rem;
    padding: 0.5rem 1rem;
    background-color: $secondary-purple-100;
    color: $secondary-purple-900;

    .icon {
        vertical-align: middle;
        margin-right: 5px;
    }

    h3 {
        margin-bottom: 0.75rem;
        margin-top: 0.5rem;
        font-weight: 600;
        color: $secondary-purple-900;
    }

    .highlightedText {
        color: $secondary-purple-500;
    }

    .checkboxes {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        font-weight: 500;
        padding: 1rem 0.5rem;

        input {
            &:checked + span {
                background-color: $secondary-purple-500;
                border: 2px solid $secondary-purple-500;
            }

            + span {
                border: 2px solid $secondary-purple-900;
            }
        }
    }
}

@import 'config/sass/vars';

.heading {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    margin: 0 auto;
    width: 66%;
    max-width: $breakpoint-desktop;
    padding: 1.6rem 0;
    border-radius: 40px 40px 40px 0px;
    background-color: $primary-mid-green-2;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;

    h1 {
        color: $primary-off-white;
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 1rem;
        padding: 0.25rem 1rem;
        border-radius: 1.25rem 1.25rem 1.25rem 0px;
        display: block;
        width: auto;

        h1 {
            margin: 1.5rem 0;
        }
    }
}

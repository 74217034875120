@import 'config/sass/vars';

.form {
    background-color: white;
    padding: 2rem 4rem;
    margin: 2rem auto;
    width: 75%;
    max-width: $breakpoint-desktop;
    border-radius: 40px;
    border: 1px solid rgba($primary-mid-green-2, 0.1);

    select {
        width: 100%;
    }

    p {
        margin-bottom: 2rem;
    }

    button {
        min-width: 33%;
        margin-bottom: 1.5rem;
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 0;
        border-radius: 0;
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem 1.5rem 2rem;
    }
}

.confirmation_checkboxes {
    display: flex;
    gap: 2rem;
    font-weight: 500;
    padding: 1.7rem 0.5rem;
    flex-wrap: wrap;

    input {
        &:checked + span {
            background-color: $secondary-purple-500;
            border: 2px solid $secondary-purple-500;
        }

        + span {
            border: 2px solid $secondary-purple-900;
        }
    }
}

.title_highlight {
    color: $secondary-purple-500;
}

.confirmation_pg_modal_title {
    color: $secondary-purple-900;
    margin-top: 0;
    margin-bottom: 1rem;
}

.modal_confirmation {
    margin: 0;
}

.submitError {
    color: red;
}

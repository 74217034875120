@import 'config/sass/vars';

.countdown b {
    font-weight: 700;
}

.promoBanner {
    &.desktop {
        @media (max-width: calc($breakpoint-mobile + 150px)) {
            display: none;
        }

        position: sticky;
        top: 0;
        z-index: 30;
        padding: 0 1.8rem;
        transition: all 1s ease-in-out;
        background-color: $secondary-purple-100;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .content {
            color: $secondary-purple-900;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.75rem 0;
            text-align: center;
            justify-content: center;
            column-gap: 1rem;
            width: 100%;

            h3 {
                color: inherit;
                margin: 0;
                padding: 0 1rem;
            }

            .tc {
                padding: 0 1rem;
            }

            .countdown {
                min-width: min(30%, 200px);
                display: flex;
                flex-direction: row;
                column-gap: 0.75rem;
                padding: 0 1rem;

                .unit {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .value {
                        font-family: $font-heading;
                        font-weight: 700;
                        font-size: 2rem;
                        line-height: 1.75rem;
                    }

                    .text {
                        font-size: 0.85rem;
                    }
                }
            }

            .plansLink {
                min-width: max-content;
                padding: 0 1rem;

                svg {
                    margin-left: 1rem;
                    transform: rotate(-90deg);

                    path {
                        stroke: $secondary-purple-500;
                    }
                }
            }
        }
    }

    &.hidePromoBanner {
        > div {
            margin-top: -100%;
            opacity: 0;
        }
    }
}

.mobile {
    @media (min-width: calc($breakpoint-mobile + 150px)) {
        display: none;
    }

    transition: all 1s ease-in-out;
    background-color: $secondary-purple-100;
    display: flex;
    align-items: center;
    gap: 1.5rem;

    &.countdownRow {
        position: sticky;
        top: 0;
        z-index: 30;
        background-color: $secondary-purple-900;
        flex-direction: row;
        justify-content: center;
        color: $primary-off-white;
        padding: 0.5rem 1rem;

        .offerEndsText {
            font-size: 1.125rem;
            font-weight: 600;
        }

        .countdown {
            min-width: min(80%, 150px);
            display: flex;
            flex-direction: row;

            &.countdownFar {
                justify-content: center;
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }

            &.countdownNear {
                justify-content: space-between;
            }

            .unit {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 0.8rem;

                .value {
                    font-family: $font-heading;
                    font-weight: 700;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &.content {
        color: $secondary-purple-900;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        text-align: center;
        justify-content: space-between;
        z-index: 29;

        h3 {
            color: inherit;
            margin: 0;
            font-size: 1.5rem;
        }

        span {
            font-size: 0.9rem;
        }

        .tc {
            span:nth-child(0) {
                //extend h3?
                font-family: $font-heading;
                font-weight: 700;
                font-size: 1.5rem;
            }
        }

        .plansLink {
            min-width: max-content;

            svg {
                margin-left: 1rem;
                transform: rotate(-90deg);

                path {
                    stroke: $secondary-purple-500;
                }
            }
        }
    }
}

@import 'config/sass/vars';

.inputButtonGroupContainer {
    margin-bottom: 1.5rem;

    span {
        @include h3;

        color: $primary-dark-green;
        margin-bottom: 1rem;
        display: block;
    }

    fieldset {
        display: flex;
        background-color: #fff;
        border-radius: 3rem;
        border: 1px solid $secondary-green-900;
        padding: 0.25rem;
        margin-bottom: 0.25rem;
        justify-content: space-between;

        input {
            flex-grow: 1;
            font-size: 1rem;
            width: 100%;
            color: $primary-dark-green;
            background-color: transparent;
            border: none;
            border-radius: 1.25rem;
            outline: none;
            padding: calc(1rem - 0.25rem);
        }

        button {
            padding: calc(1rem - 0.25rem) calc(3rem - 0.25rem);
        }
    }

    &.error {
        fieldset {
            border-color: $error;

            input,
            span {
                color: $error;
            }
        }
    }

    .errorMessage {
        color: $error;
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-weight: 600;
    }
}

@import 'config/sass/vars';

.button {
    font-size: 1rem;
    cursor: pointer;
    font-weight: 400;
    font-family: $font-body;
    padding: 1rem 3rem;
    border-radius: 3rem;
    border: none;
    transition: background-color 150ms ease-in-out;

    &.primary {
        background-color: $primary-yellow;
        color: $primary-dark-green;

        &:hover,
        &:focus {
            background-color: $primary-highlight-green;
            color: $primary-dark-green;
        }
    }

    &.purple {
        background-color: $secondary-purple-500;
        color: $primary-off-white;

        &:hover,
        &:focus {
            background-color: $secondary-purple-900;
            color: $primary-off-white;
        }

        &:disabled {
            color: $secondary-purple-900 !important;
            background-color: rgba($primary-dark-green, 0.1) !important;
        }
    }

    &.dark {
        background-color: $primary-dark-green;
        color: $primary-yellow;

        &:hover,
        &:focus {
            background-color: $primary-off-white;
            color: $primary-dark-green;
        }
    }

    &.transparent {
        background-color: $primary-off-white;
        color: $primary-dark-green;
        border: 1px solid $primary-dark-green;

        &:hover,
        &:focus {
            background-color: $primary-dark-green;
            color: $primary-yellow;
        }
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $secondary-grey-200 !important;
        color: $primary-dark-green !important;
    }

    &.fullWidth {
        width: 100%;
    }

    .loading {
        border: 4px solid $secondary-grey-200;
        border-top: 4px solid $primary-mid-green-2;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        animation: spin 2s linear infinite;
        vertical-align: bottom;
        display: inline-block;
        margin-right: 5px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

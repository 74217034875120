@import 'config/sass/vars';

.controlForm {
    display: flex;
    gap: 5rem;
    justify-content: center;
    margin: 0 auto 0.5rem;
    max-width: calc($breakpoint-desktop - 200px);
    padding: 0 2rem;

    @media (max-width: $breakpoint-mobile) {
        align-items: center;
        flex-direction: row;
        gap: 0rem;
        margin-bottom: 1rem;
    }

    .billingControls {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        gap: 1rem;
        height: fit-content;
        justify-content: center;
        margin: 0;

        @media (max-width: $breakpoint-mobile) {
            display: inline-block;
            font-size: 0.75rem;
            text-align: center;
        }

        h5 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        > div {
            background-color: #fff;
            border: 1px solid rgba($primary-dark-green, 0.3);
            border-radius: 2rem;
            display: flex;
            height: 2rem;
            justify-content: space-between;
            overflow: hidden;
            padding: 0.2rem;
            position: relative;

            input {
                left: -10px;
                position: absolute;
                top: -10px;
            }

            label {
                border-radius: 2rem;
                cursor: pointer;
                padding: 0.5rem 0.75rem;
            }

            input:checked + label {
                background-color: $primary-dark-green;
                color: $primary-yellow;
            }
        }
    }

    .contributionControls {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        height: fit-content;
        justify-content: center;
        margin: 0;

        @media (max-width: $breakpoint-mobile) {
            display: inline-block;
            text-align: center;
        }

        h5 {
            font-size: 1.5rem;
            margin-bottom: 1rem;

            button {
                background-color: transparent;
                border: none;
                margin-left: 0.5rem;
                padding: 0;
                vertical-align: middle;
            }
        }

        .contributionPrice {
            pointer-events: none;
        }

        .contributionButton {
            cursor: pointer;
        }
    }
}

.swapTC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    overflow: hidden;
    padding: 1rem 0 0;
    width: 75%;

    @media (max-width: calc($breakpoint-mobile + 300px)) {
        flex-direction: column-reverse;
        gap: 1rem;
        padding: 0;
        text-align: center;
    }

    .swapTypeLink {
        align-items: center;
        display: flex;
        letter-spacing: 0;

        button {
            background-color: transparent;
            border: none;
            color: $secondary-purple-500;
            font-size: 1em;
            padding: 0;
            text-decoration: underline;
        }
    }

    .restrictions {
        text-align: center;
    }
}

.popover {
    font-size: 1rem;
    position: relative;
    text-align: left;
    width: fit-content;

    .popoverTitle {
        text-decoration: underline;

        &:hover {
            font-weight: 500;
        }
    }

    .popoverContent {
        background-color: $primary-highlight-green;
        border-radius: 1.25rem;
        font-size: 0.875rem;
        left: 10rem;
        letter-spacing: 0;
        opacity: 0;
        padding: 1rem;
        position: absolute;
        text-align: left;
        top: 15px;
        visibility: hidden;
        width: 20rem;

        h3 {
            font-family: $font-body;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 0;
            margin: 0 0 1rem;
        }
    }

    &:hover .popoverContent {
        opacity: 1;
        visibility: visible;
        z-index: 10;
    }
}

.plansTableContainer {
    display: block;
    text-align: center;

    .hoWidth {
        width: 20%;
    }

    .llWidth {
        width: 25%;
    }

    .plansTable {
        background-color: #fff;
        border: 1px solid rgba($primary-dark-green, 0.3);
        border-radius: 2rem;
        display: inline-block;
        margin: 0 1rem 0;
        padding: 3rem;

        td,
        th {
            border: none;
            text-align: center;

            &.essentialsDisabled {
                color: #808080;

                svg path {
                    fill: #808080;
                }
            }
        }

        .essentialsPrice {
            vertical-align: bottom;
        }

        tr {
            height: 35px;

            > td {
                font-size: 0.75rem;
                vertical-align: middle;

                &.inclusionCell {
                    padding: 0rem 1.25rem;
                    vertical-align: top;
                    width: 200px;
                }
            }

            &.inclusions h4 {
                margin: 0;
            }
        }

        @media (max-width: calc($breakpoint-mobile + 300px)) {
            display: none;
        }

        .ctmLogo {
            align-items: flex-end;
            display: flex;
            height: 75px;

            span {
                display: block;
                text-align: left;
            }

            svg {
                height: 60px;
                width: 225px;
            }
        }

        .tableHeader {
            border-top-left-radius: 1.25rem;
            border-top-right-radius: 1.25rem;
            margin: 0 0.75rem 0;
            min-width: 150px;
            padding: 1rem 0.75rem;
            position: relative;

            h4 {
                color: inherit;
                margin: 0;
            }

            &.essentialsDisabled {
                background-color: #eaeaea;
                color: #808080;
            }

            .discount {
                color: $secondary-purple-900;
                font-family: $font-heading;
                font-size: 1.25rem;
                font-weight: 700;
                height: 64px;
                height: 0;
                line-height: 85%;
                padding-top: 1rem;
                position: absolute;
                right: -15px;
                top: -32px;
                width: 64px;
                z-index: 10;

                &::before {
                    background-color: $secondary-purple-100;
                    border-radius: 100%;
                    content: '';
                    height: 64px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 64px;
                    z-index: -1;
                }
            }
        }

        .pricesRow {
            h2 {
                margin: 0.25rem 0 0.25rem;
            }

            .strikeSpacer {
                height: 0.75rem;
            }

            span {
                display: block;
                font-size: 0.75rem;
                margin-bottom: 1rem;
            }

            h4 {
                > svg {
                    position: absolute;
                    transform: scale(0.75);
                    transform: translate(-25%, -2px); //transform: translate(-7px, -2px); ?

                    path {
                        stroke: $primary-dark-green;
                    }
                }

                color: inherit;
                font-size: 1rem;
                margin: 1rem 0 0 0;
            }

            .spacer {
                height: 18px;
            }

            h4 {
                > svg {
                    position: absolute;
                    transform: scale(0.75);
                    transform: translate(-25%, -2px); //transform: translate(-7px, -2px); ?

                    path {
                        stroke: $primary-dark-green;
                    }
                }

                color: inherit;
                margin: 1rem 0 0 0;
                font-size: 1rem;
            }

            .spacer {
                height: 18px;
            }
        }

        .ctaRow {
            button {
                font-size: 0.8rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
            }
        }
    }
}

.bullets {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .inclusionsPoint {
        align-items: center;
        display: flex;
        gap: 1rem;
        text-align: start;

        svg {
            height: 1rem;
        }

        p {
            width: 80%;
        }
    }
}

.plansCarousel {
    display: none;
    width: 100%;

    @media (max-width: calc($breakpoint-mobile + 300px)) {
        display: block;
    }
}

.voucherRow {
    .voucher {
        background-color: $primary-highlight-green;
        border-radius: 6px;
        font-weight: 500;
        margin: auto;
        max-width: 6rem;
        padding: 0.6rem 0;
        width: 80%;
    }
}

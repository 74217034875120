@import 'config/sass/vars';

.container {
    width: 100%;
    max-width: $breakpoint-desktop;
    margin-bottom: 1rem;

    .widget {
        font-size: 2rem;
        margin: 0rem 3rem;

        &.desktop {
            display: block;
            @media (max-width: $breakpoint-mobile) {
                display: none;
            }
        }
        &.mobile {
            display: none;

            @media (max-width: $breakpoint-mobile) {
                display: block;
            }
        }
    }

    h2 {
        font-size: 4rem;
        letter-spacing: -0.04rem;
        text-align: center;
        margin-bottom: 3rem;
        padding: 0 1rem;
    }

    @media (max-width: $breakpoint-mobile) {
        iframe {
            height: 350px !important;
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
    }
}

@import 'config/sass/vars';

.paymentMethodList {
    display: none;

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 1.5rem;

        li {
            margin-bottom: 1.5rem;
            cursor: pointer;

            .eachPaymentMethod {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .directDebitLogo svg {
                    width: 95px;
                }

                > div {
                    display: flex;
                    gap: 1rem;
                }
            }
        }
    }

    hr {
        margin-bottom: 1.5rem;
        height: 1px;
        background-color: rgba($primary-dark-green, 0.2);
        border: none;
    }
}

.makePaymentButton {
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
    width: 295px;
    max-width: 90%;
}

// Temp change for A/B test
.hideNewTCsBlurb {
    display: none;
}

.hideNewPaymentButton {
    display: none;
}

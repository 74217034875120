@import 'config/sass/vars';

.outputMessage {
    margin-top: -1rem;
    margin-bottom: 1rem;

    button {
        font-weight: 700;
        background: none;
        border: none;
        padding: 0;
        font-size: inherit;
        color: $primary-dark-green;
        text-decoration: underline;
        margin-left: 0.5rem;
        cursor: pointer;
    }
}

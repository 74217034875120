@import 'config/sass/vars';

.cardModal {
    > div {
        width: 500px !important;
    }

    .logo {
        width: 180px;
        height: 35px;

        > svg {
            height: 100%;
            width: 100%;
        }

        @media (max-width: $breakpoint-mobile) {
            width: 140px;
            height: 27px;
        }
    }

    h2 {
        margin-bottom: 1.25rem;
    }

    h3 {
        margin-top: 0;
    }

    hr {
        height: 1px;
        margin: 1.8rem 0 2.8rem;
        background-color: rgba($primary-dark-green, 0.2);
        border: none;
    }

    .submitButtonContainer {
        .submitButton {
            margin-top: 2.5rem;
            width: 298px;
            max-width: 90%;

            @media (max-width: $breakpoint-mobile) {
                margin-top: 2rem;
            }
        }

        @media (max-width: $breakpoint-mobile) {
            text-align: center;
        }
    }
}

.securePaymentText {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.75rem;

    > span {
        opacity: 0.8;
        font-size: 0.75em;
    }

    svg {
        width: 50px;
    }
}

@import 'config/sass/vars';

.desktopOnly {
    @media (max-width: calc($breakpoint-mobile + 150px)) {
        display: none !important;
    }
}

.mobileOnly {
    @media (min-width: calc($breakpoint-mobile + 150px)) {
        display: none !important;
    }
}

.navbar {
    background-color: $primary-off-white;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba($primary-dark-green, 0.3);
    position: sticky;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 99;

    &.disabled {
        top: 0px;
    }

    &.noCountdown {
        top: 50px;
    }

    &.countdown {
        top: 68px;
    }

    // Sucky logic because they keep making it sticky then not sticky... so temporary fix for two lines
    @media (max-width: 990px) {
        &.noCountdown {
            top: 77px;
        }
    }

    @media (max-width: 1023px) {
        &.countdown {
            top: 77px;
        }
    }

    @media (max-width: calc($breakpoint-mobile + 150px)) {
        &.disabled {
            top: 0px;
        }

        &.noCountdown {
            top: 35px;
        }

        &.countdown {
            top: 60px;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 2.5rem;
        margin-inline: auto;

        .logos {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 35%;

            .vr {
                width: 3px;
                height: 50px;
                margin: 0rem 0.5rem;
                background-color: $primary-dark-green;
            }

            .ctm {
                display: flex;
                flex-direction: column;

                svg {
                    width: 170%;
                }
            }
        }

        .trustpilot {
            transform: scale(1.1);
            width: 25%;
        }

        span {
            color: $primary-dark-green;
            text-decoration: none;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 500;
        }

        svg {
            width: 2rem;
            height: auto;
            vertical-align: middle;
        }

        .logo {
            max-width: 160px;
            margin-right: 0.85rem;

            svg {
                width: 100%;
                height: auto;
            }
        }

        .contactUs {
            align-items: center;
            display: flex;
            font-size: 22px;

            .tpContainer {
                padding-right: 15px;
            }
        }
    }

    @media (max-width: calc($breakpoint-mobile + 150px)) {
        margin-bottom: 0;

        .content {
            padding: 0.75rem 1rem;

            span {
                max-width: 120px;
                font-size: 1rem;
                text-align: center;
                line-height: 1.5;
            }
        }

        button {
            // copied from omitsis as per request
            padding: 13px 27px;
        }
    }
}

div.contactUsModal {
    > div {
        width: 400px !important;
        padding: 24px 40px !important;
        background-color: $primary-yellow !important;

        > button {
            top: 1rem !important;
            right: 1rem !important;
            font-size: 1.9rem !important;
        }

        h2 {
            font-size: 3.25rem;
            text-align: center;
            margin: 50px 0 2rem !important;
        }

        a {
            text-decoration: none;
        }

        a + span {
            display: block;
            text-align: center;
            font-weight: 500;
        }

        hr {
            border-top: 1px solid rgba($primary-dark-green, 0.3);
            border-bottom: 0;
            margin: 2rem 0;
        }

        p {
            line-height: 1.5rem;
        }

        strong {
            font-weight: 500;
        }

        a button {
            background-color: $primary-dark-green !important;
            color: $primary-yellow !important;
            display: block;
            margin: 0 auto 1rem;
        }

        h3 {
            // margin-bottom: 10px;
            margin: 1.5rem 0 0.65rem;
        }

        // hide footer
        div:last-of-type {
            display: none;
        }
    }
}

@import 'config/sass/vars';

.orderForm {
    > div > hr {
        margin-top: 1.5rem;
    }

    .productList {
        ul {
            &.productListCol {
                grid-template-rows: repeat(5, min-content);
                grid-auto-flow: column;
            }

            &.productList2Cols {
                grid-template-columns: repeat(2, auto);
                grid-auto-flow: row;
            }

            display: grid;
            column-gap: 1rem;
            list-style-type: none;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1.5rem;

                h3 {
                    margin: 0;
                }

                svg {
                    width: 16px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }

        + hr {
            margin-bottom: 1.25rem;
        }

        @media (max-width: $breakpoint-mobile) {
            svg {
                width: 18px;
            }

            ul li {
                gap: 0.7rem;
                margin-bottom: 1rem;
                font-size: 1.25rem;
            }
        }
    }

    .orderAdjustments {
        position: relative;

        .addons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 700;
            margin: 1.5rem 0;

            .threeYearFixed {
                display: flex;
                flex-direction: row;

                .threeYearFixedTitle {
                    margin: 0;
                }

                .threeYearFixedPopover {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .threeYearFixedPopoverContent {
                        .threeYearFixedContent {
                            padding: 1.5rem;
                            display: block;

                            .threeYearFixedContentTitle {
                                margin: 0;
                            }

                            > p {
                                margin: 1rem 1rem 0 1rem;
                            }
                        }
                    }
                }
            }
        }

        .contribution {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            margin: 1.5rem 0;

            h3 {
                margin: 0;
            }

            span {
                display: flex;
                flex-direction: column;

                h4 {
                    margin: 0;
                }

                .callout {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
        }

        .popover {
            span {
                svg {
                    margin-left: 0.5rem;
                    margin-bottom: 4px;
                }

                &:hover svg {
                    fill: $primary-highlight-green;
                }
            }

            .popoverContent {
                background-color: $primary-highlight-green;
                border-radius: 1.25rem;
                font-size: 0.875rem;
                left: 0;
                letter-spacing: 0;
                opacity: 0;
                position: absolute;
                text-align: left;
                top: 1.5rem;
                visibility: hidden;
                width: 320px;
                max-width: 100%;
                font-weight: 400;

                p {
                    margin: 1.5rem;
                }
            }

            &:hover .popoverContent {
                opacity: 1;
                visibility: visible;
                z-index: 10;
            }
        }

        @media (max-width: $breakpoint-mobile) {
            > div {
                grid-template-columns: 1fr 1.6fr 1fr;
            }
        }
    }

    .totalPrice {
        background: $primary-mid-green-2;
        color: $primary-off-white;
        padding: 1.5rem 1.625rem;
        margin: -0.25rem -1.6rem -1.5rem;
        border-radius: 0 0 1rem 1rem;

        @media (max-width: $breakpoint-mobile) {
            padding: 1rem 1.625rem;
            border-radius: 0;
        }

        .voucher {
            display: flex;
            justify-content: initial;
            width: 190px;
            height: 33px;
            padding: 5px 16px 4px 11px;
            align-items: center;
            flex-shrink: 0;
            border-radius: 8px;
            background: $secondary-green-100;
        }

        .voucherIcon {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
        }

        .voucherText {
            display: flex;
            width: 156px;
            height: 21px;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;

            color: $primary-dark-green;
            text-align: center;
            font-family: 'Instrument Sans', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.64px;
        }

        > div {
            display: flex;
            justify-content: space-between;

            h3 {
                color: $primary-off-white;
                margin-top: 0.75rem;
            }

            @media (max-width: $breakpoint-mobile) {
                h3 {
                    margin-bottom: 0;
                }
            }

            h2 {
                color: $primary-highlight-green;
                margin: 0;
            }

            .priceValues {
                margin: 0rem 0 1.5rem 0;

                @media (max-width: $breakpoint-mobile) {
                    margin: 0rem 0 0.5rem 0;
                }

                .spacer {
                    display: block;
                    height: 1.5rem;
                }

                .ogPriceWrapper {
                    display: inline-block;
                    float: right;

                    > svg {
                        position: absolute;
                        transform: scale(1.125);
                    }

                    .originalPrice {
                        margin: 0 0 0.5rem 0;
                        text-align: end;
                        color: $primary-mid-green-1;
                    }
                }
            }
        }
    }

    b {
        font-weight: 700;
    }
}

@import 'config/sass/vars';

body section.inclusions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4rem;
    width: $breakpoint-desktop;
    max-width: 100%;
    margin: 0rem 0 5rem 0;
    padding: 0 1rem;
    box-sizing: border-box;

    @media (max-width: calc($breakpoint-mobile + 200px)) {
        flex-direction: column;
        align-items: center;
        gap: 2.75rem;
        margin: 2rem 0 2rem 0;
    }

    .heading {
        min-width: 311px;

        h2 {
            margin-top: 0;
            text-align: left;
        }

        > h1 {
            text-align: left;
            margin-bottom: 2.5rem;
            margin: 0 0 2.5rem 0;
        }

        @media (max-width: calc($breakpoint-mobile + 200px)) {
            min-width: 100%;
            text-align: center;

            h2 {
                font-size: 2.5rem;
                margin-bottom: 0.75rem;
            }

            p {
                font-size: 1rem;
                letter-spacing: 0.02rem;
            }
        }
    }
}

.items {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2.5rem;

    @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
    }

    h5 {
        margin: 0 0 0.5rem;
        font-size: 1.5rem;

        + span {
            font-size: 0.875rem;
        }
    }

    .item {
        .icon {
            background-color: $primary-yellow;
            border-radius: 0.5rem;
            padding: 1rem;

            svg {
                width: 80px;
                height: 80px;
            }

            @media (max-width: $breakpoint-mobile) {
                svg {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        display: flex;
        flex-direction: row;
        gap: 1.5rem;
    }
}

@import 'config/sass/vars';

.form {
    background-color: white;
    padding: 2rem 4rem;
    margin: 2rem auto;
    width: 75%;
    max-width: $breakpoint-desktop;
    border-radius: 40px;
    border: 1px solid rgba($primary-mid-green-2, 0.1);
    box-sizing: border-box;

    h2 {
        margin-bottom: 0.75rem;
    }

    p {
        margin-bottom: 2rem;
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 0;
        border-radius: 0;
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem 1.5rem 2rem;
    }
}

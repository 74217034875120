@import "config/sass/vars";

.checkboxContainer {
    label {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
    }

    input {
        &:checked {
            + span {
                background-color: $primary-mid-green-2;
                border: 2px solid $primary-mid-green-2;

                svg {
                    opacity: 1;
                }
            }
        }

        opacity: 0;
        position: absolute;
        z-index: -1;

        + span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.125rem;
            width: 1.125rem;
            border: 2px solid $primary-mid-green-2;
            border-radius: 5px;
            cursor: pointer;

            svg {
                opacity: 0;
            }
        }
    }

    .checkboxChildren {
        padding-top: 2px;
    }
}

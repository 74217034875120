@import 'config/sass/vars';

.header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.checkoutContainer {
    h1 + p {
        margin-bottom: 1.65rem;
    }

    @media (max-width: $breakpoint-mobile) {
        h1,
        h1 + p {
            display: none;
        }
    }
}
